body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Muli",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 50px; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w-full {
  width: 100% !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.header {
  grid-column-start: 2;
  grid-column-end: span 3;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 1.5rem 0px 0px;
  min-height: 56px;
}
.header span {
  font-size: 14px;
  color: #707070;
}

.header span a,
.policyTextStyle a {
  color: #02a8ee !important;
  cursor: pointer;
  font-weight: 600;
}
.formWraper {
  width: 454px;
  margin: auto;
}
.formWraper h3 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #707070;
}
.outerSpanCircle {
  position: relative;
  height: 12px;
  width: 12px;
  border: 1px solid #1d9eb8;
  border-radius: 50%;
}
.innerSpanCircle {
  position: absolute;
  height: 6px;
  width: 6px;
  top: 2px;
  left: 2px;
  background-color: #02a8ee;
  border: 1px solid #1d9eb8;
  border-radius: 50%;
}
.btnImg {
  background-image: url("https://app.sbizzl.com/assets/images/google-logo.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25px;
  left: 1px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  vertical-align: middle;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
button.signupButton {
  background: #299af3 !important;
  width: 100%;
  padding-left: 50px;
  text-transform: none;
  margin-top: 30px;
  height: 50px;
  font-size: 14px;
}
.formdivider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 30px 0px;
}
.formdivider:after {
  content: "";
  width: 100%;
  height: 0.5px;
  background: #707070;
  position: absolute;
  left: 0;
  right: 0;
}
.formdivider span.classOr {
  z-index: 99;
  background: #fff;
  height: 18px;
  width: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #bcbcbc;
}
button.nextButton {
  width: 100%;
  margin: 20px 0px 0px 0px;
  height: 50px !important;
  font-size: 14px !important;
  text-transform: capitalize;
  background: #02a8ee !important;
}
button.nextButton.Mui-disabled {
  background-color: #eaf0f6 !important;
  border-color: #eaf0f6 !important;
  color: #b0c1d4 !important;
  cursor: not-allowed;
}
.policyTextStyle {
  text-align: center;
  word-break: break-word;
  margin: 30px 0px !important;
  display: block;
  font-size: 12px;
  color: #707070 !important;
  line-height: 25px;
  padding: 0px 12px;
}

.formWraperDiv {
  width: 450px;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 80vh;
}
.formWraperDiv h3 {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
}
span.textPrimary {
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 600;
  color: #86919e;
}
span.textPrimary span {
  color: #171717;
  font-weight: 600;
}
span.textSecondary {
  font-size: 17px;
  color: #86919e;
  font-weight: 600;
}
.formLinkView {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.formLinkView span.textSecondary a {
  margin-top: 4px;
  font-size: 17px;
  color: #02a8ee;
  font-weight: 500;
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;
}
.formWraper .forTextStyle h3 {
  text-align: left;
  font-size: 32px;
  color: #707070;
  margin-top: 5px;
}
.formWraper .forTextStyle h5 {
  text-align: left;
  font-size: 18px;
  color: #86919e;
}
.forTextStyle {
  margin-bottom: 35px;
}

.primaryColor {
  color: #707070;
}
.secondaryColor {
  color: #192d3e;
}
p.backButton {
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0px 0px 0px;
  cursor: pointer;
  align-items: center;
  display: flex;
  color: #00a0e3 !important;
  text-transform: capitalize;
}
p.backButton svg.MuiSvgIcon-root {
  font-size: 14px;
  color: #707070 !important;
}
p.backButton svg.MuiSvgIcon-root path {
  fill: #707070 !important;
}
.iconButtonCss {
  padding: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-left: 0px !important;
  width: 20px;
}
.iconButtonCss button {
  padding: 0px !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.autocompleteText {
  position: relative;
}
.autocompleteText .searchIcon {
  position: absolute;
  right: 2px;
  bottom: 0;
  color: rgba(0, 0, 0, 0.54) !important;
}
.autocompleteText div.MuiInputBase-root {
  padding-right: 30px;
}

.customInput {
  font-size: 18px;
  line-height: 18px;
  height: 32px;
  width: 100% !important;
}
.customInput input {
  padding: 6px 0px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: #f87757;
}
.MuiInput-underline:before {
  display: none;
}
.MuiInput-underline:after {
  border-bottom-color: #02a8ee !important;
  border-bottom-width: 1px !important;
  transform: inherit !important;
}

.inputControl {
  min-height: 66px;
  margin-bottom: 10px;
}
.inputControl .MuiFormHelperText-root.errorText ,.inputControl.autocompleteErr .MuiFormHelperText-root.Mui-error{
  color: #f87757 !important;
  font-size: 10px !important;
  line-height: 18px;
  font-weight: 400;
  margin: 5px 0px 0px;
}

.customInputAuto {
  border: 0px;
  outline: none;
  box-shadow: none;
  padding: 6px 30px 6px 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
.googlePlaceAuto label {
  display: none;
}
.googlePlaceAuto div.MuiInputBase-root.MuiInput-root {
  margin-top: 0px;
}

.googlePlaceAuto {
  position: relative;
}
.googlePlaceAuto .searchIcon {
  position: absolute;
  top: 5px;
  right: 0px;
  color: #bcbcbc;
  font-weight: 600;
  padding: 3px;
}
.text-center {
  text-align: center !important;
}
.googlePlaceAuto .searchIcon svg.MuiSvgIcon-root {
  font-weight: 600;
  fill: #bcbcbc;
  font-size: 18px;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bcbcbc !important;
  font-weight: 600 !important;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #bcbcbc !important;
  font-weight: 600 !important;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #bcbcbc !important;
  font-weight: 600 !important;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #bcbcbc !important;
  font-weight: 600 !important;
}

.stepper-progress-bar {
  position: fixed;
  width: 100%;
  height: 8px;
  top: 0;
  z-index: 9;
  left: 0;
  right: 0;
  background: #02a8ee;
  transition: width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
}

.bgProgress {
  width: 100%;
  background: rgb(234 240 246);
  height: 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.6s ease-in-out;
}

.imagBlockContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.imagBlock {
  width: 450px;
}
.imagBlock h5 {
  font-size: 16px;
  color: #707070;
  font-weight: 600;
  margin: 17px 0px 0px 0px;
}
.imagBlock span {
  font-size: 12px;
  color: #86919e;
  margin-top: 8px;
  display: inline-block;
}

button.inputButtonAd {
  padding: 5px;
  color: #bcbcbc;
  margin-right: 0;
  font-weight: 400;
}

ul.listStepPwd {
  padding-bottom: 20px;
}
ul.listStepPwd li {
  color: #86919e;
  padding: 5px 0px;
}
ul.listStepPwd li div.MuiListItemText-root {
  margin: 0px;
}
ul.listStepPwd li div.MuiListItemText-root span.MuiTypography-root {
  color: #86919e;
  font-size: 14px;
  padding-left: 10px;
}
ul.listStepPwd li .MuiSvgIcon-root {
  font-size: 1rem;
}

.change::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #e3e3e3 !important;
  font-weight: 600 !important;
}
.change:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #e3e3e3 !important;
  opacity: 1;
  font-weight: 600 !important;
}
.change::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #e3e3e3 !important;
  opacity: 1;
  font-weight: 600 !important;
}
.change:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e3e3e3 !important;
  font-weight: 600 !important;
}

.ViewContainer {
  display: grid;
  grid-template-columns: 1.25rem 3.125rem auto 3.125rem 1.25rem;
  grid-template-rows: minmax(2.5rem, max-content) 0px auto minmax(
      2.5rem,
      max-content
    );
  grid-template-areas:
    "left-gutter header header header right-gutter"
    "left-gutter subheader subheader subheader right-gutter"
    "left-gutter main main main right-gutter"
    "left-gutter submain submain submain right-gutter";
  min-height: 100vh;
  /* padding-top: 0.5rem; */
  max-width: 32.5rem;
  margin: 0px auto;
}

.ViewComponent {
  display: grid;
  grid-template-rows: minmax(65vh, max-content) auto;
  grid-area: main / main / main / main;
}
.ViewComponent.signupProcess {
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
}
.signupProcessInner {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.signupProcessInner span.textPrimary {
  font-size: 18px;
  color: #707070;
  margin: 50px 0px 50px 0px;
}

.signupProcessInner a {
  background: #02a8ee;
  width: 450px;
  height: 50px;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
}

.inputControl .autocompleteCountry .MuiAutocomplete-inputRoot {
  padding: 1px !important;
}
.inputControl
  .autocompleteCountry
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
  outline: none !important;
}
.inputControl .autocompleteCountry .MuiAutocomplete-endAdornment {
  right: 0 !important;
}
.inputControl .autocompleteCountry .MuiOutlinedInput-notchedOutline {
  padding-left: 8px;
  border: 0px;
  border-bottom: 1px solid #02a8ee !important;
  border-radius: 0px !important;
}
.inputControl
  .autocompleteCountry
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #02a8ee !important;
  border-width: 1px !important;
}

.spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}
.spinner-wrapper.dashboard {
  position: absolute;
  width: 100px;
  min-height: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  overflow: hidden;
}
.dash-dropdown .MuiSelect-select:focus {
  background: transparent !important;
}

.spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

.spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

.spinner-wrapper .spinner .inner .left,
.spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

.spinner-wrapper .spinner .inner .left .half-circle,
.spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 10px solid #009fe2;
  border-bottom-color: transparent;
  border-radius: 50%;
}

.spinner-wrapper .spinner .inner .left {
  left: 0;
}

.spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.spinner-wrapper .spinner .inner .right {
  right: 0;
}

.spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1)
    infinite;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {
  0%,
  100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {
  0%,
  100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.centerProgress {
  justify-content: center;
  align-self: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 999;
  color: "#009fe2";
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.34);
  z-index: 99999;
}

@media screen and (min-width: 1025px) {
  .ViewContainer {
    grid-template-columns: 2.5rem 3.125rem auto 3.125rem 2.5rem;
    grid-template-rows: minmax(2.5rem, max-content) 0px auto minmax(
        2.5rem,
        max-content
      );
    max-width: 100vw;
    margin: 0px auto;
  }
  .ViewComponent {
    width: 100%;
    justify-self: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
  }
}

@media screen and (min-width: 1600px) {
  .ViewContainer {
    max-width: calc(100% - (0.1 * (100% - 1600px)));
  }
}
@media screen and (min-width: 1201px) {
  .ViewComponent {
    max-width: calc(100% - (1 * (100% - 1201px)));
  }
}
